<template>
    <footer class="footer">
        <div class="footer__top">
            <div class="footer__top__info">
                <p>V-Tax Lybaert BV</p>
                <p>Vliegtuiglaan 5</p>
                <p>9000 Gent</p>
                <p><a href="tel:+3292222222">09/222.22.22</a></p>
                <p><a href="mailto:info@v-tax.be">info@v-tax.be</a></p>
                <div class="footer__top__social">
                    <a class="footer__top__social__button" target="_blank"
                       href="https://www.facebook.com/pages/V-Tax/146968518845013">
                        <fa-icon :icon="['fab','facebook-f']"></fa-icon>
                    </a>
                </div>
            </div>
        </div>
        <div class="footer__bottom">
            <a target="_blank" href="https://www.privalex.be/nl/bedrijven/848234f13c">Privacy</a>
            | <router-link to="/terms">Voorwaarden</router-link>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "TheFooter"
    }
</script>