<template>
    <div id="app">
        <TheHeader></TheHeader>
        <router-view/>
        <TheFooter></TheFooter>
    </div>
</template>

<script>
    import TheHeader from "./components/structure/TheHeader";
    import TheFooter from "./components/structure/TheFooter";
    export default {
        components:{
            TheHeader,
            TheFooter
        }
    }
</script>

<style lang="scss">
    @import "assets/scss/app.scss";
</style>
